import { render, staticRenderFns } from "./DropdownSort.vue?vue&type=template&id=c418408e&scoped=true"
import script from "./DropdownSort.vue?vue&type=script&lang=ts"
export * from "./DropdownSort.vue?vue&type=script&lang=ts"
import style0 from "./DropdownSort.vue?vue&type=style&index=0&id=c418408e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c418408e",
  null
  
)

export default component.exports