












































import { Component, Vue, Emit, Prop } from 'nuxt-property-decorator'

@Component
export default class DropdownSort extends Vue {
  @Prop({ default: '' }) title: String
  @Prop({ default: true }) toUppercaseTitle: boolean
  @Prop({ default: false }) toBoldFont: boolean
  @Prop({ default: true }) useTextBrandBase: boolean
  @Prop({ default: true }) addPaddingTitle: boolean
  @Prop({ default: [] }) sortOptions!: Array<{
    text: string
    value: string
  }>

  isOpen: boolean = false

  openDropdown() {
    this.isOpen = true
  }

  closeDropdown() {
    this.isOpen = false
  }

  @Emit('change')
  selectOption(value: string) {
    this.isOpen = false
    return value
  }
}
