import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import BaseState from '~/models/state/baseState'
import { projectStore, builderStore } from '~/store'
import { getCurrentUser } from '~/api/user.api'
import { Project, User, Collaborator } from '~/models'
import { navigation, ACCESS_DISABLED_ROUTE } from '~/utils/navigationAuth'
import { RoleTypes } from '~/enums/users'
import { ProjectError } from '~/utils/exceptions'

@Module({
  name: 'user',
  stateFactory: true,
  namespaced: true,
})
export default class Auth extends VuexModule implements BaseState {
  currentUser: User

  get user(): User {
    return this.currentUser
  }

  get projects(): Project[] {
    return this.currentUser ? this.currentUser.projects : []
  }

  get project(): Project {
    return this.currentUser?.projects.find(
      (p) => p.id === projectStore.currentProject
    )
  }

  get first_name(): string {
    if (!this.currentUser) {
      return null
    }

    return this.currentUser.first_name
  }

  get email(): string {
    if (!this.currentUser) {
      return null
    }

    return this.currentUser.email
  }

  get role(): string {
    if (!this.currentUser) {
      return null
    }

    return this.currentUser.role
  }

  get emailNotifications(): boolean {
    if (!this.currentUser) {
      return false
    }
    return this.currentUser.email_notifications
  }

  get isSpecialFeatureEnabled(): boolean {
    if (!this.project || !this.project.config) {
      return false
    }
    return this.project.config.enable_special_feature
  }

  get isConstructionModuleEnabled(): boolean {
    if (!this.project || !this.project.config) {
      return false
    }
    return this.project.config.enable_construction_module
  }

  get isAutoCancellationDateEnabled(): boolean {
    if (!this.project || !this.project.config) {
      return false
    }
    return this.project.config.enable_auto_today_cancellation_date
  }

  get isEnableSpecBulkSubmission(): boolean {
    if (!this.project || !this.project.config) {
      return false
    }
    return this.project.config.enable_spec_bulk_submission
  }

  get isEnabledAutoFillPlanFeature(): boolean {
    if (!this.project || !this.project.config) {
      return false
    }
    return this.project.config.enable_autofill_plan_feature
  }

  get id(): number {
    return this.currentUser?.id
  }

  get builderName(): string {
    return this.currentUser?.builder_name
  }

  get collaborators(): Collaborator[] {
    return this.user?.collaborators || []
  }

  get requiredDocuments(): Array<string> {
    const project: Project = this.project
    if (!project || !project.config) {
      return []
    }
    return project?.config.required_documents
  }

  get requiredSubCategory(): boolean {
    const project: Project = this.project
    if (!project || !project.config) {
      return false
    }

    return this.project?.config?.is_require_subcategory
  }

  get redirectUrlByProject(): string {
    if (!this.project || !this.project.config) {
      const message: string = !this.project
        ? null
        : `The project ${this.project.name} has not config assigned`
      throw new ProjectError(message)
    }

    let navItem = navigation.find(
      // @ts-ignore
      (item) => this.project.config[item.enabledConfigKey] === true
    )

    if (!navItem && this.isAdmin) {
      navItem = navigation.find((item) => item.allowedFor === RoleTypes.ADMIN)
    }

    if (navItem !== undefined) {
      return navItem.path
    }

    return ACCESS_DISABLED_ROUTE
  }

  get isAdmin(): boolean {
    return this.currentUser?.is_admin || false
  }

  get isGuest(): boolean {
    return this.user?.is_guest || false
  }

  get isGuestAbleToUploadDocs(): boolean {
    return this.user?.guest_upload_doc || false
  }

  get isDeveloper(): boolean {
    return RoleTypes.DEVELOPER === this.role
  }

  get isBuilder(): boolean {
    return RoleTypes.BUILDER === this.role
  }

  get hasElevationBulkEnabled(): boolean {
    const project: Project = this.project
    if (!project || !project.config) {
      return false
    }
    return project.config.enable_bulk_elevations
  }

  get isCutomReportEnabled(): boolean {
    const project: Project = this.project
    if (!project || !project.config) {
      return false
    }
    return project.config.enable_custom_report
  }

  get notificationDate(): Date {
    if (!this.currentUser?.notification) {
      return
    }

    let date: string = this.currentUser.notification
    date = date.replace('Z', '')
    return new Date(date)
  }

  get isAbleToEdit(): boolean {
    if (!this.currentUser?.guest_reviewer) {
      return !this.isGuest
    }
    return (!this.isGuest && this.currentUser?.guest_reviewer) || false
  }

  get isMatrixMaterials(): boolean {
    const project: Project = this.project
    if (!project || !project.config) {
      return false
    }

    return project.config.secondary_material_matrix
  }

  get isClosingStatementEnabled(): boolean {
    if (!this.project || !this.project.config) {
      return false
    }
    return this.project.config.enable_closing_statement
  }

  get isBuilderContractVisible(): boolean {
    if (!this.project || !this.project.config) {
      return false
    }

    return this.project.config.enable_builder_contract
  }

  get isContractsNetEnabled(): boolean {
    if (!this.project || !this.project.config) {
      return false
    }
    return this.project.config.contracts_enabled
  }

  get isEnhancedElevationsEnabled(): boolean {
    const project: Project = this.project
    if (!project || !project.config) {
      return false
    }
    return project.config.enhanced_elevations
  }

  get isParcelIQViewModeEnabled(): boolean {
    if (!this.currentUser) {
      return false
    }
    return this.currentUser.parcel_iq_view_only_user
  }

  get isClosingStatementViewModeEnabled(): boolean {
    if (!this.currentUser) {
      return false
    }
    return this.currentUser.closing_statement_view_only_user
  }

  @Action({ rawError: true })
  async getUser() {
    try {
      await this.getUserData()
      const projects: Array<Project> = this.user?.projects
      if (projects.length) {
        projectStore.changeActiveProject(projects[0].id)
      }

      if (this.user?.role === 'builder') {
        builderStore.changeActiveBuilder(this.user?.id)
      }
    } catch (exception) {
      console.log(exception)
    }
  }

  @Action({ rawError: true })
  async getUserData() {
    try {
      const user = await getCurrentUser()
      this.setUser(user)
    } catch (exception) {
      console.log(exception)
    }
  }

  @Action({ rawError: true })
  setNotificationDateTime(date: string) {
    try {
      this.setNotification(date)
    } catch (exception) {
      console.log(exception)
    }
  }

  @Mutation
  setUser(user: User) {
    this.currentUser = { ...user }
  }

  @Mutation
  clear(): void {
    this.currentUser = null
  }

  @Mutation
  setNotification(date: string): void {
    if (!this.currentUser) {
      return
    }
    this.currentUser.notification = date
  }
}
